//
// toastr.scss
//

.jq-toast-single {
    border-radius: 0.25rem!important;
    padding: 12px !important;
    font-family: $font-family-base;
    background-color: #000000;
    color: #ffffff !important;
    font-size: 17px;
    line-height: 24px;
    h2 {
        font-family: $font-family-base;
        font-size: 18px;
        color: #ffffff !important;
    }
    a {
        font-size: $font-size-base;
        &:hover {
            color: $white;
        }
        color: #ffffff !important;
    }
}

.jq-has-icon {
    padding: 20px;
}

.close-jq-toast-single {
    visibility: hidden;
}

.jq-toast-loader {
    height: 5px;
    top: 0;
    border-radius: 0;
}

@each $color,$value in $theme-colors {
    .jq-icon-#{$color} {
        background-color: #{$value};
        color: $white;
        border-color: #{$value};
    }
}

// For error
.jq-icon-error {
    background-color: $danger;
    color: $white;
    border-color: $danger;
}
