/*
Template Name: Hyper - Responsive Bootstrap 4 Admin Dashboard
Version: 1.5.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "config/variables";
@import "config/custom-variables";
@import "./node_modules/bootstrap/scss/bootstrap";


// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/right-sidebar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
// horizontal nav
@import "custom/structure/horizontal-nav";


//Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


//Components
@import "custom/components/mixins";
@import "custom/components/accordions";
@import "custom/components/avatar";
@import "custom/components/breadcrumb";
@import "custom/components/buttons";
@import "custom/components/badge";
@import "custom/components/card";
@import "custom/components/dropdown";
@import "custom/components/forms";
@import "custom/components/modal";
@import "custom/components/nav";
@import "custom/components/pagination";
@import "custom/components/popover";
@import "custom/components/print";
@import "custom/components/progress";
@import "custom/components/reboot";
@import "custom/components/switch";
@import "custom/components/tables";
@import "custom/components/type";
@import "custom/components/utilities";
@import "custom/components/widgets";
@import "custom/components/social";
@import "custom/components/steps";


//Pages
@import "custom/pages/authentication";
@import "custom/pages/buttons-demo";
@import "custom/pages/error";
@import "custom/pages/faq";
@import "custom/pages/grid-demo";
@import "custom/pages/icons-demo";
@import "custom/pages/maintenance";
@import "custom/pages/tasks";


// Vendors
@import "vendor/daterangepicker";
@import "vendor/bootstrap-datepicker.min";
@import "vendor/jquery.toast.min";
@import "vendor/select2.min";
@import "vendor/jquery.toast.min";
@import "vendor/bootstrap-timepicker.min";
@import "vendor/jquery.bootstrap-touchspin.min";


// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/britechart";
@import "custom/plugins/calendar";
@import "custom/plugins/chartjs";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange";
@import "custom/plugins/datepicker";
@import "custom/plugins/dragula";
@import "custom/plugins/dropzone";
@import "custom/plugins/form-wizard";
@import "custom/plugins/maps";
@import "custom/plugins/metis-menu";
@import "custom/plugins/select2";
@import "custom/plugins/slimscroll";
@import "custom/plugins/toaster";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/bootstrap-timepicker";
@import "custom/plugins/summernote";
@import "custom/plugins/simplemde";
@import "custom/plugins/typehead";
@import "custom/plugins/sparklines";
