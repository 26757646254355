//
// reboot.scss
//


button, a {
  outline: none !important;
}

// Forms

label {
  font-weight: $font-weight-semibold;
}

// Address
address.address-lg {
  line-height: 24px;
}
