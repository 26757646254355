//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 0px;
  overflow-x: hidden !important;
}

.outer {
    margin-top: 20px;
    width: 125px;
    white-space: nowrap;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.outer div {
    float: none !important;
    display: inline-block !important;
}

.btn-light{
  border-color: #adb5bd !important;
  font-weight: bold !important;
}



.card {
      border-radius: 0.25rem !important;
}


.card-body {
    padding: 0.8rem;
}

.card .header-title {
    text-transform: none !important;
    font-size: 1.1rem !important;
}

p {
    margin-bottom: 0.3rem;
}

.btn-sm {
    font-size: 0.95rem;
}

.navbar-custom {
    -webkit-box-shadow: none;
    box-shadow: none;
}


.form-control {
    border-radius: 0rem !important;
    }

.select2-container--default .select2-selection--single {
    border-radius: 0.2rem !important;
}

.table td, .table th {
    padding: 0.3rem !important;
    border-top: none !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  font-size: 1rem !important;
}

.horizontal-steps .horizontal-steps-content .step-item {
    height: 20px !important;
    width: 20px !important;
}
.horizontal-steps .horizontal-steps-content .step-item span {
    font-size:1rem !important;
}
.horizontal-steps .horizontal-steps-content .step-item.current span {
    color: #003f88;
  font-size: 1rem !important;
  font-weight: bold !important;
}
.horizontal-steps .horizontal-steps-content .step-item.current:before {
    top: 47.5%;
    left: 50% !important;
    padding: 4em !important;

}
body{
        font-size: .91rem !important;
}

.page-title {
    line-height: 70px !important;
}

#scroll-horizontal-datatable > thead{
  display: none !important;
}



#html_element{
  margin-bottom: 20px;
  margin-top: -10px;
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 90%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1rem;
}

.dz-drag-hover .dropzone{
  background-color: #ffdf6b !important;
  color:#000 !important;
}

#notificacionM2{
  padding-left: 30px !important;
  padding-right: 30px !important;
}

#notificacionAR{
  padding-left: 30px !important;
  padding-right: 30px !important;

}

.jq-toast-wrap {
    width: 400px !important;
  -webkit-box-shadow: 0 0 15px 0 rgba(154,161,171,0.6) !important;
    box-shadow: 0 0 15px 0 rgba(154,161,171,0.6) !important;
}



.jq-toast-single {

    margin-bottom: 0px !important;
}

.page-title-box .page-title-right {
    margin-top: 15px !important;
}

.btn-danger {
    background-color: #d62828;
    border-color: #d62828;
}
.btn{
  font-weight: bold !important;
}

.table .action-icon {
    background: #023e8a;
    font-size: 1.3rem !important;
}
.modal-open .modal {
    background: rgba(0,0,0,0.55);
}

a .card{
  color:#000000;
}

.form-control-light {
    background-color: #fff!important;
}

.table .action-icon {
    background-color: #003f88!important;
    color: #fff!important;
    font-size: 1.5rem!important;
    -webkit-box-shadow: 0 2px 6px 0 rgba(0,51,103,.3);
    box-shadow: 0 2px 6px 0 rgba(0,51,103,.3);
    padding: 5px !important;
    border-radius: 0.2rem;
}

.btn-primary{
background: #2a9d8f !important;
}

.btn-danger{
  background: #e63946 !important;
}

.btn{
  padding: 0.55rem .9rem !important;
  font-size:0.92rem !important;
  border: none !important;
  border-radius: 0.2rem!important;
  text-transform: uppercase;

}

.btn.focus, .btn:focus {
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
}

.btn.hover, .btn:hover {
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
}

.btn-warning{
 background: #fcbf49 !important;
}

.form-control {
    border-radius: 0.2rem!important;
  padding: 1.3rem .97rem !important;
  font-size:0.95rem !important;

      background-color: #e8e8e8 !important;
    border: none !important;
}

label {
    margin-bottom: .2rem !important;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .15rem;
}

.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
    border-radius: 0 !important;
}

input[data-switch]+label {
    width: 100px !important;
    height: 38px !important;
}

input[data-switch]+label:after {
    height: 32px !important;
    width: 32px !important;
}

input[data-switch]:checked+label:after {
    left: 64px !important;
}

.select2-container .select2-selection--single {
    height: 44px !important;
}

label {
    font-weight: 500 !important;
    font-size: 1rem !important;
}

p {
    font-size: 1rem !important;
}

.btn-primary:hover {
    border-color: #6dc2ff !important;
}

.btn-light {
    color: #000000 !important;
}

.table .action-icon {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.datepicker-days{
  font-size: 1rem !important;
}

.badge {
    padding: .5em .4em;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0.3rem;
}

.btn-info {
    background: rgb(51,149,177);
}

.btn-success {
   background: rgb(1,193,138);
background: linear-gradient(124deg, rgba(1,193,138,1) 0%, rgba(13,129,69,1) 100%);
}

.rounded {
    border-radius: 0.5rem!important;
}
.border {
    background-color: #ffffff !important;
  box-shadow: none !important;
}

.dropzone {
    border: none !important;
  background-color: #e8e8e8 !important;
  border-radius: 0.2rem !important;
}

.modal-footer{
  padding: 0.5rem !important;
  border:none !important;
}

.modal-header {
   padding: 0.5rem !important;
  border:none !important;
}

.modal-body {
      font-size: 1rem;
   padding: 0.5rem !important;
  border:none !important;
}

.modal-header .close {
    background: #e63946;
  color:#fff;
  opacity: 1 !important;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0,0,0,.5);
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.5);
  padding: .5rem .8rem!important;
    font-size: 1.7rem!important;
  border-radius: 0.4rem;
}

.modal-content {
    border-radius: 0.3rem !important;
    padding: 4px !important;
}

.table .action-icon-danger{
  background: #e63946 !important;
}

.select2-selection--multiple {
    border-radius: 0.2rem!important;
    padding: 0.3rem 0.28rem!important;
    font-size: 1rem!important;
}

.enlarged .side-nav-item:hover .side-nav-link:hover{
    border-radius: 0.2rem !important;
    background: #0077b6 !important;

}

@media (min-width: 576px){
.modal-dialog {
    max-width: 700px;
}
}

body{
  background-color: #f1f3f4 !important;
}