//
// Select 2
//
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #e8e8e8;
}

.select2-container {
    width: 100%!important;

    .select2-selection--single {
        border: none;
        height: $input-height;
        background-color: #e8e8e8;
        outline: none;
        .select2-selection__rendered {
            line-height: 42px;
            padding-left: 12px;
            font-size: 0.97rem;
            color: $input-color;
        }
        .select2-selection__arrow {
            height: 40px;
            width: 40px;
            right: 1px;
            b {
                border-color: $gray-900 transparent transparent transparent;
                border-width: 6px 6px 0 6px;
            }
        }
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent $gray-900 transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 9px 12px;
    font-size: 1rem;
}

.select2-dropdown {
    border: $dropdown-border-width solid $dropdown-border-color;
    box-shadow: $shadow;
    border-radius: 0rem !important;
    background-color: $dropdown-bg;
}

.select2-container--default {
    .select2-search--dropdown {
        padding: 10px;
        background-color: lighten($dropdown-bg,2.5%);
        .select2-search__field {
            outline: none;
            border: none;
            background-color: #e8e8e8;
            color: $input-color;
            border-radius: 0.2rem;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary;
    }
    .select2-results__option[aria-selected=true] {
        background-color: lighten($dropdown-bg,2.5%);
        color: $dropdown-link-active-color;
        &:hover {
            background-color: $primary;
            color: $white;
            border-radius: 0rem;
        }
    }
}

.select2-container {
    .select2-selection--multiple {
        min-height: $input-height;
        border: none;
        background-color: #e8e8e8;

        font-size: 1rem!important;

        .select2-selection__rendered {
            padding: 1px 10px;
        }
        .select2-search__field {
            border: 0;
            color: $input-color;
        }
        .select2-selection__choice {
            background-color: $primary;
            border: none;
            color: $white;
            border-radius: 0px;
            padding: 0 7px;
            margin-top: 6px;
        }
        .select2-selection__choice__remove {
            color: $white;
            margin-right: 5px;
            &:hover {
                color: $white;
            }
        }
    }
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    border-radius: 0rem;
}

.select2-container--default .select2-search--dropdown {
    background-color: #fff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    padding: 0.6rem .97rem!important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: none;
    outline: 0;
}
