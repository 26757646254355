//
// faq.scss
//

.faq-question-q-box {
    height: 30px;
    width: 30px;
    color: $white;
    background-color: $primary;
    box-shadow: $shadow-lg;
    text-align: center;
    border-radius: 50%;
    float: left;
    font-weight: $font-weight-bold;
    line-height: 30px;
}

.faq-question {
    margin-top: 0;
    margin-left: 50px;
    font-weight: 400;
    font-size: 16px;
    color: $gray-900;
}

.faq-answer {
    margin-left: 50px;
}
